<template>
  <a class="link"><slot></slot></a>
</template>

<script>
export default {
  name: 'Link',
};
</script>

<style lang="scss" src="@/sass/06_atoms/link.scss"></style>
