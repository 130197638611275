<template>
  <span class="icon">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: 'Icon',
};
</script>

<style scoped lang="scss" src="@/sass/06_atoms/icon.scss"></style>
